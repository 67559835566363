footer {
    background: var(--color-secondary);
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 0rem;
}

.footer_logo {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: inline-block;
}

.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
}

.footer_socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
}

.footer_socials a {
    font-size: 1.8rem;
    padding: 0.8rem;
    display: flex;
}

.footer_copyright {
    margin-bottom: 4rem;
}

/* -------------- Media Query (Small Devices) ----------------- */
@media screen and (max-width: 600px){
    .permalinks{
        flex-direction: column;
        gap: 1.4rem;
    }

    .footer_socials{
        margin-bottom: 2.6rem;
    }
}