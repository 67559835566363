.title {
    margin-bottom: 1rem;
}

.subtitle {
    margin-bottom: 2.5rem;
}

.container.contact_container {
    width: 65%;
    display: grid;
    grid-template-columns: 30% 60%;
    gap: 12%;
}

.contact_options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact_option {
    background: var(--color-secondary);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact_option:hover {
    background: transparent;
    border-color: var(--color-secondary);
}

.contact_icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact_option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 1rem;
}

/* --------------------- FORM -------------------- */
form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    margin-top: 1.5rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary);
    resize: none;
    color: black;
}

/* --------------- Media Queries (Medium Devices) ----------------- */
@media screen and (max-width: 1024px) {
    .container.contact_container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .contacts {
        height: 70rem;
    }
}

/* --------------- Media Queries (Small Devices) ------------------ */
@media screen and (max-width: 600px) {
    .contacts {
        height: 65rem;
    }

    .container.contact_container{
        width: var(--column-variables-sm)
    }
}